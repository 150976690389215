import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";

type DialogPanelProps = {
  handleClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
};

const DialogPanel: React.FC<DialogPanelProps> = ({
  handleClose,
  onConfirm,
  title,
  message,
}) => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
        <Dialog.Title
          as="h3"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          {/* You can add the title here */}
        </Dialog.Title>
        <div className="w-full flex flex-col text-center items-center justify-center space-y-1 sm:space-y-4">
          {/* Add your custom content here */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            className="w-24 h-24"
          >
            <path
              fill="#034c9e"
              d="m14.952,6.46c.225,1.275-.381,2.549-1.506,3.169-.593.326-.946.839-.946,1.371v.5c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-.5c0-.903.548-1.743,1.464-2.247.762-.42,1.156-1.252,1.004-2.119-.142-.803-.8-1.461-1.601-1.602-.605-.104-1.192.048-1.652.436-.454.382-.715.939-.715,1.532,0,.276-.224.5-.5.5s-.5-.224-.5-.5c0-.889.391-1.727,1.071-2.298s1.576-.81,2.469-.654c1.207.211,2.2,1.203,2.412,2.412Zm-2.952,6.54c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm9-4c0,2.522-1.023,4.857-2.882,6.576-1.366,1.263-2.118,2.886-2.118,4.571v.353c0,1.93-1.57,3.5-3.5,3.5h-1c-1.93,0-3.5-1.57-3.5-3.5v-.348c0-1.707-.718-3.321-1.97-4.431-2.262-2.003-3.36-4.999-2.939-8.014C3.644,3.736,6.943.521,10.936.061c2.565-.294,5.139.514,7.052,2.22,1.915,1.707,3.013,4.156,3.013,6.72Zm-6,11.147c0-.387.034-.77.1-1.147h-6.195c.063.378.095.763.095,1.152v.348c0,1.379,1.121,2.5,2.5,2.5h1c1.379,0,2.5-1.121,2.5-2.5v-.353Zm5-11.147c0-2.278-.976-4.455-2.678-5.973-1.485-1.325-3.343-2.028-5.312-2.028-.317,0-.638.019-.961.056-3.547.408-6.478,3.264-6.969,6.792-.374,2.682.603,5.346,2.612,7.127.921.816,1.596,1.865,1.97,3.026h6.69c.391-1.165,1.099-2.246,2.086-3.158,1.652-1.527,2.562-3.602,2.562-5.842Z"
            />
          </svg>
          <span className="font-medium text-2xl text-slate-900">{title}</span>
          <span className="text-base">{message}</span>
          <div className="flex flex-col sm:flex-row justify-center w-full space-y-3 sm:space-y-0 sm:space-x-11">
            <ButtonCSCLed
              type="button"
              className="w-full"
              onClick={onConfirm}
            >
              Yes please
            </ButtonCSCLed>
            <ButtonSecondary
              type="button"
              className="w-full border border-slate-200 dark:border-slate-700"
              onClick={handleClose}
            >
              Cancel
            </ButtonSecondary>
          </div>
        </div>
      </Dialog.Panel>
    </Transition.Child>
  );
};

export default DialogPanel;
