import React, { FC } from "react";
import { Link } from "react-router-dom";
import { JSX } from "react/jsx-runtime";

interface SellSheetPaginationProp {
  className?: string;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

const SellSheetPagination: FC<SellSheetPaginationProp> = ({
  className = "mt-10",
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const renderPaginationItems = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pages: JSX.Element[] = [];
    const delta = 1; // Number of pages to show around the current page
     
    if (totalPages <= 1) return pages;

    const addPage = (i: number) => {
      pages.push(
        <li key={i}>
          {i === currentPage ? (
            <span className="ml-1 inline-flex w-11 h-11 items-center justify-center rounded-full bg-custom-Blue text-white">
              {i}
            </span>
          ) : (
            <Link
              to="#"
              className="ml-1 inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
              onClick={() => onPageChange(i)}
            >
              {i}
            </Link>
          )}
        </li>
      );
    };

    if (currentPage > 1) {
      pages.push(
        <li key="prev">
          <Link
            to="#"
            className="ml-1 inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
            onClick={() => onPageChange(currentPage - 1)}
          >
            &lt;&lt;
          </Link>
        </li>
      );
    }

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - delta && i <= currentPage + delta)) {
        addPage(i);
      } else if (i === currentPage - delta - 1 || i === currentPage + delta + 1) {
        pages.push(
          <li key={`dots-${i}`}>
            <span className="inline-flex w-11 h-11 items-center justify-center">...</span>
          </li>
        );
      }
    }

    if (currentPage < totalPages) {
      pages.push(
        <li key="next">
          <Link
            to="#"
            className="ml-1 inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
            onClick={() => onPageChange(currentPage + 1)}
          >
            &gt;&gt;
          </Link>
        </li>
      );
    }

    return pages;
  };

  return (
    <nav className={className}>
      <ul className="flex list-none p-0 justify-end">{renderPaginationItems()}</ul>
    </nav>
  );
};

export default SellSheetPagination;
