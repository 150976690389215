import React, { FC, useEffect, Fragment, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import axiosInstance from "api/AxiosInstance";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import Backdrop from "../../shared/Confirmdialogbox/Backdrop";
import DialogPanel from "../../shared/Confirmdialogbox/DialogPanel";
import SellSheetPagination from "containers/SellSheet/SellSheetPagination";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import HeadingText1 from "components/HeadingText/HeadingText1";
import { useLocation } from "react-router-dom";

export interface ShippingAddressProps {
  className?: string;
}

const ShippingAddressPage: FC<ShippingAddressProps> = ({ className = "" }) => {


  const location = useLocation();
  const { redirect } = location.state || {};

  //const jsonString = localStorage.getItem("user");
  const user = useAppSelector((state: RootState) => state.auth);
  const [shippingaddress, setShippingAddress] = useState<any>([]);
  const dtRef = useRef<any>(null);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calculate start and end index for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);

  // Get data for the current page
  let currentData = filteredData.slice(startIndex, endIndex);

  // end of tailwind sorting
  const [sortedField, setSortedField] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState("asc");

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle sorting
  const handleSort = (field: any) => {
    if (field === sortedField) {
      toggleSortDirection();
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  // Function to sort data
  let sortedData = [...currentData].sort((a, b) => {
    if (sortDirection === "asc") {
      return a[sortedField] > b[sortedField] ? -1 : 1;
    } else {
      return a[sortedField] < b[sortedField] ? -1 : 1;
    }
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const showDeleteConfirmation = (e: any, rowData: any) => {
    setSelectedItem(rowData);
    setShowConfirmation(true);
    handleOpen();
  };

  const handleOpen = () => {
    setIsOpen(true);
    setShowModal(false);
  };

  const handleDelete = () => {
    // Perform delete action
    setShowConfirmation(false);
    callDeleteAPI(selectedItem);
  };

  const callDeleteAPI = async (itemId: any) => {
    try {
      const id = itemId;
      await axiosInstance.get(
        `/ShippingAddress/DeleteShippingAddress?id=${id}`
      );
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const exportCSV = () => {
    if (dtRef.current?.exportCSV) {
      dtRef.current.exportCSV();
    } else {
      console.error("dtRef.current is null or undefined");
      // Provide fallback behavior or handle the null case as needed
    }
  };

  const fetchData = async () => {
    const response = await axiosInstance
      .get(`/ShippingAddress`)
      .then((response) => {
        setShippingAddress(response.data);
        setFilteredData(response.data);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = async (e: any) => {
    if (
      e.target.value !== null &&
      e.target.value !== undefined &&
      e.target.value !== ""
    ) {
      const query = e.target.value;
      setSearchQuery(query);
      const filtered = filteredData.filter((item: any) => {
        return (
          item.companyName.toLowerCase().includes(query.toLowerCase()) ||
          (item.addressField2 &&
            item.addressField2.toLowerCase().includes(query.toLowerCase())) ||
          (item.addressFields &&
            item.addressFields.toLowerCase().includes(query.toLowerCase())) ||
          (item.alternativePhoneNumber &&
            item.alternativePhoneNumber
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (item.city &&
            item.city.toLowerCase().includes(query.toLowerCase())) ||
          (item.country &&
            item.country.toLowerCase().includes(query.toLowerCase())) ||
          (item.emailAddress &&
            item.emailAddress.toLowerCase().includes(query.toLowerCase())) ||
          (item.firstName &&
            item.firstName.toLowerCase().includes(query.toLowerCase())) ||
          (item.lastName &&
            item.lastName.toLowerCase().includes(query.toLowerCase())) ||
          (item.phoneNumber &&
            item.phoneNumber.toLowerCase().includes(query.toLowerCase())) ||
          (item.postalCode &&
            item.postalCode.toLowerCase().includes(query.toLowerCase())) ||
          (item.province &&
            item.province.toLowerCase().includes(query.toLowerCase()))
        );
      });
      setShippingAddress(filtered);
      setFilteredData(filtered);
      sortedData = filteredData.slice(startIndex, endIndex);
    } else {
      setSearchQuery("");
      fetchData();
    }
  };

  return (
    <div
      className={`nc-ShippingAddressPage ${className}`}
      data-nc-id="ShippingAddressPage"
    >
      <Helmet>
        <title>Shipping Addresses | CSC LED</title>
      </Helmet>
      <div className="w-full">
        {/* <CommonLayout> */}
        <main className="container py-16 lg:pb-28 lg:pt-20 pt-8">
          <div className="mb-10">
            <HeadingText1 className="font-poppins">
              Your Shipping Addresses
            </HeadingText1>
          </div>
          <div className="space-y-10 sm:space-y-12">
            {/* Tailwind Table start */}
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
               <Link to={'/add-shipping-address/shipping'}>
                <ButtonPrimary
                  className="capitalize sm:!px-7 shadow-none"
                  //href="/add-shipping-address/shipping"
                >
                  add shipping address
                </ButtonPrimary>
                </Link>
              
              </div>

              <div className="flex md:justify-end justify-start w-full md:w-80 sm:w-full">
                <div className="mt-4 md:ml-2 ml-0  w-full max-w-full md:max-w-lg lg:max-w-xs md:mt-0 sm:flex-auto">
                  <label htmlFor="search" className="sr-only"></label>
                  <div className="relative">
                    <input
                      id="search"
                      name="search"
                      className="block w-full py-3.5 rounded-md border-0 shadow pl-10 pr-3 text-gray-900 bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                      placeholder="Search here"
                      type="search"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-blue-900"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 sm:mt-8 md:mt-8 lg:mt-8 xl:mt-8 2xl:mt-8 w-full flex">
              <div className="flex w-full">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="custom-scrollbar max-h-[600px] overflow-auto bg-gray-50 border rounded-lg">
                    {/* <div className="p-0 listingSectionSidebar__wrap lg:shadow-lg lg:h-[82vh] lg:overflow-y-auto customScrollBar rounded-lg halloween:border-white lg:cursor-all-scroll max-h-[600px] overflow-y-auto bg-white">                         
                <div className="flex w-full">
                  <div className="inline-block min-w-full align-middle"> */}

                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 py-3.5 text-base pl-4 pr-3 capitalize text-left font-semibold text-gray-900 sm:pl-6"
                            onClick={() => handleSort("companyName")}
                          >
                            address{" "}
                            {sortedField === "companyName" && (
                              <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                            )}
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 py-3.5 text-base pl-4 pr-3 capitalize text-left font-semibold text-gray-900 sm:pl-6"
                          >
                            edit
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 z-10 py-3.5 text-base pl-4 pr-3 capitalize text-left font-semibold text-gray-900 sm:pl-6"
                          >
                            remove
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {sortedData.length !== 0 ? (
                          sortedData.map((i: any) => (
                            <tr key={i.id}>
                              <td
                                style={{ whiteSpace: "break-spaces" }}
                                className="whitespace-break-spaces whitespace-wrap py-4 pl-4 pr-10 text-sm text-gray-700 sm:pl-6"
                              >
                                { i.companyName && i.companyName }
                                { i.addressFields && "\n" + i.addressFields }
                                { (i.city || i.province || i.postalCode) &&
                                "\n" + [i.city, i.province, i.postalCode].filter(Boolean).join(", ") }
                                { i.country && "\n" + i.country }
                                { (i.firstName || i.lastName) && "\n" + [i.firstName, i.lastName].filter(Boolean).join(" ") }
                                { i.phoneNumber && "\n" + i.phoneNumber }
                                { i.alternativePhoneNumber && "\n" + i.alternativePhoneNumber }
                                { i.emailAddress && "\n" + i.emailAddress }
                              </td>

                              <td className="relative iconshover whitespace-nowrap py-4 pl-4 pr-4 text-right text-sm">
                                <a
                                  href="#"
                                  className="cursor-pointer rounded-full bg-gray-50 items-center justify-center flex w-12 h-12"
                                >   
                                {redirect === "checkout" ? (
                                   <Link
                                   className="cursor-pointer transition-all rounded-full hover:shadow-md bg-gray-50 items-center justify-center flex w-12 h-12"
                                   to={`/edit-shipping-address/checkout/${i.id}`}
                                 >
                                   <PencilSquareIcon
                                     className="h-4 w-4  text-gray-700 hover:text-primary-500 transition"
                                     aria-hidden="true"
                                   />
                                   <span className="sr-only">
                                     {/* , {person.name} */}
                                   </span>
                                 </Link>
                                ) : (
                                  <Link
                                  className="cursor-pointer transition-all rounded-full hover:shadow-md bg-gray-50 items-center justify-center flex w-12 h-12"
                                  to={`/edit-shipping-address/shipping/${i.id}`}
                                >
                                  <PencilSquareIcon
                                    className="h-4 w-4  text-gray-700 hover:text-primary-500 transition"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">
                                    {/* , {person.name} */}
                                  </span>
                                </Link>
                                )}                                                           
                                </a>
                              </td>
                              <td className="relative iconshover whitespace-nowrap py-4 pl-6 pr-4 text-right text-sm">
                                <a
                                  href="#"
                                  onClick={(e) =>
                                    showDeleteConfirmation(e, i.id)
                                  }
                                  className="cursor-pointer transition-all rounded-full hover:shadow-md bg-gray-50 items-center justify-center flex w-12 h-12"
                                >
                                  <TrashIcon
                                    className="h-4 w-4 text-gray-700 hover:text-primary-500 transition"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">
                                    {/* , {person.name} */}
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="py-10 text-center">
                              <p className="text-gray-700 font-semibold">
                                Hm, there are no shipping addresses to display.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {filteredData.length > 10 ? (
               <SellSheetPagination
               currentPage={currentPage}
               itemsPerPage={itemsPerPage}
               totalItems={filteredData?.length as number}
               onPageChange={handlePageChange}
             />
            ) : (
              ""
            )}
          </div>
        </main>
        {showConfirmation && (
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClose}>
              <Backdrop isOpen={isOpen} />
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <DialogPanel
                    title={"Are you sure?"}
                    message={
                      "If you remove this shipping address it will be gone forever!"
                    }
                    onConfirm={handleDelete}
                    handleClose={handleClose}
                  />
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </div>
    </div>
  );
};

export default ShippingAddressPage;
