import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import ShippingAddressPage from "containers/ShippingAddressPage/ShipppingAddressPage";
import TermCondition from "containers/TermCondition/TermCondition";
import PrivatePolicy from "containers/PrivatePolicy/PrivatePolicy";
import PolicyForm from "containers/PolicyForms/PolicyForms";
import EnergySavings from "containers/EnergySavings/EnergySavings";
import RebateSavings from "containers/RebateSavings/RebateSavings";
import AboutUs from "containers/AboutUs/AboutUs";
import MeetOutTeam from "containers/OurTeam/OurTeam";
import HomePage from "containers/HomePage/HomePage";
import RegisterEmailSendPage from "containers/PageSignUp/RegisterEmailSendPage";
import AddShippingAddress from "containers/ShippingAddressPage/AddShippingAddress";
import EditShippingAddress from "containers/ShippingAddressPage/EditShippingAddress";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import PageResetPassword from "containers/PageResetPasswordPage/PageResetPassword";
import RegistrationSuccessPage from "containers/PageSignUp/RegistrationSuccessPage";
import YourOrdersPage from "containers/YourOrders/YourOrdersPage";
import AutocompleteExample from "containers/ShippingAddressPage/AutocompleteExample";
import Careers from "containers/Careers/Careers";
import ApplyNow from "containers/Careers/ApplyNow";
import OpenOrdersPage from "containers/YourOrders/OpenOrdersPage";
import Rewards from "containers/Rewards/Rewards";
import Index from "containers/Cart/index";
import RewardsSignUp from "containers/Rewards/RewardsSignUp";
import RewardsLogin from "containers/Rewards/RewardsLogin";
import RewardsShop from "containers/Rewards/RewardsShop";
import TechInvAPI from "containers/TechnicalInvAPI/TechInvAPI";
import SellSheets from "containers/SellSheet/SellSheets";
import ContactUs from "containers/ContactUs/ContactUs";
import Checkout from "containers/Cart/Checkout";
import Products from "containers/Products/Products";
import ContactUsDetail from "containers/ContactUs/ContactUsDetail";
import Clearance from "containers/Clearance/Clearance";
import BackOrdersPage from "containers/YourOrders/BackOrdersPage";
import CloseOrdersPage from "containers/YourOrders/ClosedOrdersPage";
import ReviewOrder from "containers/Cart/ReviewOrder";
import ProductSheet from "containers/ProductSheets/ProductSheet";
import ProductType from "containers/ProductType/ProductType";
import ProductSeries from "containers/ProductType/ProductSeries";
import Contest from "containers/ContestPage/Contest";
import OrderPlacedPage from "containers/Cart/OrderPlaced";
import ProductDetailsCSC from "containers/ProductDetailsCsc/ProductDetailsCSC";
import PrivateRoute from "./PrivateRoute";
import NavigationListener from "components/NavigationListener";
import QRCodeRoute from "components/QRCodeRoute/QRCodeRoute";
import PageNotifications from "shared/Notification/PageNotifications";

export const pages: Page[] = [
  { path: "/", component: HomePage },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  { path: "/shipping-address", component: ShippingAddressPage },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/terms-conditions", component: TermCondition },
  { path: "/privacy-policy", component: PrivatePolicy },
  { path: "/policy-forms", component: PolicyForm },
  { path: "/energy-savings", component: EnergySavings },
  { path: "/rebate-savings", component: RebateSavings },
  { path: "/about-us", component: AboutUs },
  { path: "/our-team", component: MeetOutTeam },
  { path: "/home", component: HomePage },
  { path: "/signup-email", component: RegisterEmailSendPage },
  { path: "/add-shipping-address/:redirect", component: AddShippingAddress },
  { path: "/edit-shipping-address/:redirect/:id", component: EditShippingAddress },
  { path: "/forgot-pass", component: PageForgotPassword },
  { path: "/reset-password/:resetCode/:mode", component: PageResetPassword },
  // { path: "/registration-Success/:activation", component: RegistrationSuccessPage },
  { path: "/your-orders", component: YourOrdersPage },
  { path: "/careers", component: Careers },
  { path: "/apply-now", component: ApplyNow },
  { path: "/AutocompleteExample", component: AutocompleteExample },
  // { path: "/open-orders", component: OpenOrdersPage },
  { path: "/rewards", component: Rewards },
  { path: "/Cart/index", component: Index },
  { path: "/rewards/signup", component: RewardsSignUp },
  { path: "/rewards/rewardslogin", component: RewardsLogin },
  { path: "/rewards/rewards-shop", component: RewardsShop },
  { path: "/technical-inventory-api", component: TechInvAPI },
  { path: "/sell-sheets", component: SellSheets },
  { path: "/contact-us", component: ContactUs },
  { path: "/Cart/checkout-order", component: Checkout },
  { path: "/products", component: Products },
  { path: "/products/:seriesslug", component: Products },
  { path: "/contact-us/:pageid", component: ContactUsDetail },
  { path: "/clearance", component: Clearance },
  { path: "/Cart/review-order", component: ReviewOrder },
  { path: "/product-sheets", component: ProductSheet },


  { path: "/product-type", component: ProductType },
  { path: "/product-type/:department", component: ProductType },
  { path: "/product-series/:series", component: ProductSeries },
  { path: "/contest", component: Contest },
  { path: "/Cart/order-placed", component: OrderPlacedPage },
  { path: "/product-details/:prodid", component: ProductDetailsCSC },

  { path: "/SFAccount/Register", component: QRCodeRoute },
  { path: "/Home/Products", component: QRCodeRoute },
  { path: "/Home/ProductDetails/:pid/:productslug", component: QRCodeRoute },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <NavigationListener />
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <PageNotifications/>
      <Routes>
        <Route path="/login" element={<PageLogin />} />
        <Route element={<PrivateRoute />}>
          {pages.map(({ component: Component, path }, index) => (
            <Route key={index} element={<Component />} path={path} />
          ))}
          {/* <Route path="/edit-shipping-address/:id" element={<EditShippingAddress />} /> */}
          <Route path="/registration-success/:activation" element={<RegistrationSuccessPage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
