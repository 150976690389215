import CSCProductCard from "components/CSCProductCard";
import { BaseProp } from "entities/BaseProp";
import { ProductItem, ProductPagination } from "entities/ProductsDTO";
import React, { FC, useState } from "react";
import ServerPagination from "../../components/ServerPagination";
import Divider from "components/Divider";
import { stringToSlug } from "utils/stringToSlug";
import SellSheetPagination from "containers/SellSheet/SellSheetPagination";
import handleScrollToEl from "utils/HandleScrollToEl";

interface ProductItemsProp extends BaseProp {
  productItems: ProductPagination;
  optionalAccessoryItems: ProductPagination;
  isShowOptionalAcc: boolean;
  onPageChange: (pageNumber: number) => void;
}

const ProductItems: FC<ProductItemsProp> = ({
  className = "",
  productItems,
  optionalAccessoryItems,
  isShowOptionalAcc,
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 24;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = optionalAccessoryItems?.items?.slice(
    indexOfFirstItem,
    indexOfLastItem
  ) ?? [];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleScrollToEl("scrolloptionalacc");
  };

  const renderItemGrid = (array: ProductItem[]) => {
    return (
      <div className="flex-1 grid grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10">
        {array.map((item, index) => (
          <CSCProductCard
            key={index}
            name={item.productname}
            category={item.productnumber}
            description={item.productdesc}
            status={
              item.isclearance
                ? "Clearance"
                : item.lifecyclestatus === `End of Life` && !item.isclearance
                ? "End of Life"
                : undefined
            }
            image={item.productimageurl || undefined}
            link={`/product-details/${stringToSlug(item.productnumber).toUpperCase()}`}
            linkTarget="_self"
          />
        ))}
      </div>
    );
  };

  const renderOptionalAccessories = () => {
    return (
      <>
        <Divider />
        <div className="max-w-screen-sm scroll-mt-32" id="scrolloptionalacc">
          <h2 className="block text-2xl sm:text-3xl font-semibold">
            Associated Accessories for Products Above
          </h2>
        </div>
        {renderItemGrid(currentItems)}
        {/* PAGINATION */}
        <SellSheetPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={optionalAccessoryItems?.items?.length as number}
          onPageChange={handlePageChange}
        />
      </>
    );
  };

  const renderProductGrid = () => {
    return (
      <>
        {renderItemGrid(productItems.items)}
        {productItems.totalPages > 1 && (
          <ServerPagination
            key={"productspaging"}
            currentPage={productItems.pageIndex}
            totalPages={productItems.totalPages}
            onPageChange={onPageChange}
          />
        )}
      </>
    );
  };

  return (
    <>
      {productItems && renderProductGrid()}
      {isShowOptionalAcc &&
        optionalAccessoryItems &&
        renderOptionalAccessories()}
    </>
  );
};

export default ProductItems;
