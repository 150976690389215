import axiosInstance from "api/AxiosInstance";
import { BaseApiResponse } from "entities/ApiResponse";
import { ClearanceDTO, ClearanceFilterParameter } from "entities/ClearanceDTO";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import FilterClearance from "./FilterClearance";
import SellSheetPagination from "containers/SellSheet/SellSheetPagination";
import handleScrollToEl from "utils/HandleScrollToEl";
import SkeletonCareer from "containers/Careers/SkeletonCareer";
import CSCProductCard from "../../components/CSCProductCard";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";
import { stringToSlug } from "utils/stringToSlug";
import MatchNotFound from "components/MatchNotFound";
import HeadingText1 from "components/HeadingText/HeadingText1";
import usePagination from "hooks/usePagination";

export interface ClearanceProps extends BaseProp {}

const Clearance: FC<ClearanceProps> = ({ className = "" }) => {
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ClearanceDTO> | null>(null);

  const [filters, setFilters] = useState<ClearanceFilterParameter>({
    productTypeId: 0,
    productSeriesId: 0,
  });

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/products/getClearanceInventory")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredItems = dataResult?.data.productDataList?.filter((item) => {
    if (
      filters.productSeriesId &&
      item.productSeriesId !== filters.productSeriesId
    ) {
      return false;
    }
    if (filters.productTypeId && item.productTypeId !== filters.productTypeId) {
      return false;
    }
    const searchTextLower = filters.searchText?.toLowerCase();
    if (
      searchTextLower &&
      !(
        item.productName.toLowerCase().includes(searchTextLower) ||
        item.productNumber.toLowerCase().includes(searchTextLower)
      )
    ) {
      return false;
    }
    return true;
  });

  {/**Hook for pagination */}
  const {
    currentItems,
    currentPage,
    itemsPerPage,
    onPageChange,
    setCurrentPage,
  } = usePagination(filteredItems, 24, "scrollSeriesTab");

  const handleFilterChange = (
    filterName: string,
    value: number | string | undefined
  ) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    setCurrentPage(1);
  };

  const renderBanner = () => {
    return (
      <>
        {dataResult?.data.banner && (
          <NcImage
            className="w-full"
            containerClassName=""
            src={dataResult?.data.banner.bannersURL}
            loading="lazy"
          />
        )}
      </>
    );
  };

  const renderClearanceProduct = () => {
    return (
      <>
        {renderBanner()}
        <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
          <div className="space-y-10 lg:space-y-14">
            {/* HEADING */}
            <div className="max-w-screen-sm scroll-mt-32" id="scrollSeriesTab">
              <HeadingText1 className="font-poppins">Clearance</HeadingText1>
              <span className="block mt-4 text-sm sm:text-base text-black dark:text-neutral-400">
                Professional lighting at promotional prices
              </span>
            </div>
            <Divider />
            {dataResult?.data && (
              <main>
                {/* FILTER */}
                <FilterClearance
                  data={dataResult.data}
                  filterParam={filters}
                  handleFilterChange={handleFilterChange}
                  filteredItems={filteredItems}
                />
                {/**Render product tiles */}
                {currentItems && currentItems.length > 0 ? (
                  <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                    {currentItems.map((item, index) => (
                      <CSCProductCard
                        name={item.productName}
                        category={item.productNumber}
                        description={item.prodDescription}
                        image={item.lowResImages || undefined}
                        status="Clearance"
                        link={`/product-details/${stringToSlug(
                          item.productNumber
                        ).toUpperCase()}`}
                        key={index}
                        linkTarget="_self"
                      />
                    ))}
                  </div>
                ) : (
                  <MatchNotFound />
                )}

                {/* PAGINATION */}
                <SellSheetPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={filteredItems?.length as number}
                  onPageChange={onPageChange}
                />
              </main>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`nc-Clearance ${className}`} data-nc-id="Clearance">
      <Helmet>
        <title>Clearance | CSC LED</title>
      </Helmet>
      {dataResult?.success ? renderClearanceProduct() : <SkeletonCareer />}
    </div>
  );
};

export default Clearance;
